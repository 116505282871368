/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// webpack 5 doesn't polyfills these things.
// other way: add DefinePlugin: https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
// 'process/browser' polyfill for some reason doesn't set process to "global" object
import { Buffer } from 'buffer'
global.Buffer = global.Buffer || Buffer
global.process = require('process')
